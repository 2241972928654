import React from 'react';
import { Link } from 'react-router-dom'
import '../App.css';

export default function Project ({number, color, textColor, text, title, image, link}) {
    let colorStyle = {
        backgroundColor: color,
        color: '#ffffff'
    }

    if(textColor) {
        colorStyle = {
            backgroundColor: color,
            color: '#000000'
        }
    }
    const linkTo = '/projects/' + link;
    return(
        <div className="project" style={colorStyle}>
            <div className="project-number">{number}</div>
            <div className={"project-image " + image} ></div>
            <div className="project-footer">
                <div className="project-description">
                    <div className="project-description-text">{text}</div>
                    <div className="project-description-title">{title}</div>
                </div>

                <Link to={linkTo } className={textColor? "btn-project btn-project-black":"btn-project"} style={colorStyle}>
                    <span className="btn-project-text">Mehr erfahren</span>
                    <i className="fas fa-chevron-right"></i>
                    
                </Link>
            </div>
        </div>
        
    )
}
