import React from 'react';
import Sidebar from '../components/Sidebar';
import Project from '../components/Project';

const projects = [
    {"id": "01", "color": "#c1deba", "text": "UX / UI", "title": "profectum - Unterstützung der Medikamenteneinnahme", image: "image-profectum", "link": "profectum", "textColor": true},
    {"id": "02", "color": "#343a40", "text": "Code", "title": "Aqui - Wasserroboter", image: "image-aqui", "link": "aqui"},
    {"id": "03", "color": "#F95A6C", "text": "UX / UI", "title": "exoJack - smarte Orthese", image: "image-orthese", "link": "exojack"},
    // {"id": "03", "color": "#ffc20d", "text": "UX / UI, Code", "title": "Essensplanung", image: "image-essen", "link": "essen"},
    {"id": "04", "color": "#FF8660", "text": "UX / UI", "title": "Designing my life", image: "image-design", "link": "design"},
    // {"id": "05", "color": "#005ba9", "text": "Ausstellung", "title": "Verdeckung", image: "image-verdeckung", "link": "verdeckung"}
]

// #34da5f (grün), #005ba9 (blau), #f4b93d (orange)  41E35B

export default function Home() {
    
    return(
        <div className="page">
            <Sidebar about={false} />
            <div className="projects-container">
                { projects.map((project, index) => {
                    return <Project 
                        key={index} 
                        color={project.color} 
                        number={project.id} 
                        text={project.text} 
                        textColor={project.textColor}
                        title={project.title}
                        link={project.link}
                        image={project.image}
                    />
                })}
                
                <div className="footer">Copyright 2021 - Julia Hellmann </div>
            </div>

        </div>
    )
}