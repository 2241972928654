import React, { useEffect, useRef } from 'react';
import Sidebar from '../components/Sidebar';

export default function About() {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const scrollBtn = useRef(null);

  

    return(
        <div className="page-about">

            <div className="scroll-btn" onClick={scrollToTop} ref={scrollBtn}><i className="fas fa-chevron-up"></i></div>
            

            <Sidebar about={true} />
            <div className="abouts-container">
                <div className="image-container container">
                    <div className="me"></div>
                </div>
                <div className="skills-container container">
                   
                    <div className="skill">
                        <h3>Sprachen</h3>
                        <div className="skill-logo-wrapper">
                            <div className="skill-box">
                                <div className="skill-logo logo-german"></div>
                                <div className="skill-text">Deutsch</div>
                            </div>
                            <div className="skill-box">
                                <div className="skill-logo logo-english"></div>
                                <div className="skill-text">Englisch</div>
                            </div>
                            <div className="skill-box">
                                <div className="skill-logo logo-spanish"></div>
                                <div className="skill-text">Spanisch</div>
                            </div>
                        </div>
                    </div>

                    <div className="skill">
                        <h3>Programmieren</h3>
                        <div className="skill-logo-wrapper">
                        <div className="skill-box">
                                <div className="skill-logo logo-html"></div>
                                <div className="skill-text">HTML</div>
                            </div>
                            <div className="skill-box">
                                <div className="skill-logo logo-css"></div>
                                <div className="skill-text">CSS</div>
                            </div>
                            <div className="skill-box">
                                <div className="skill-logo logo-js"></div>
                                <div className="skill-text">Javascript</div>
                            </div>
                            <div className="skill-box">
                                <div className="skill-logo logo-arduino"></div>
                                <div className="skill-text">Arduino</div>
                            </div>
                            <div className="skill-box">
                                <div className="skill-logo logo-php"></div>
                                <div className="skill-text">php</div>
                            </div>
                            <div className="skill-box">
                                <div className="skill-logo logo-sql"></div>
                                <div className="skill-text">MySQL</div>
                            </div>
                        </div>
                    </div>

                    <div className="skill">
                        <h3>Design</h3>
                        <div className="skill-logo-wrapper">
                            <div className="skill-box">
                                <div className="skill-logo logo-figma"></div>
                                <div className="skill-text">Figma</div>
                            </div>
                            <div className="skill-box">
                                <div className="skill-logo logo-miro"></div>
                                <div className="skill-text">Miro</div>
                            </div>
                            <div className="skill-box">
                                <div className="skill-logo logo-xd"></div>
                                <div className="skill-text">Adobe XD</div>
                            </div>
                            <div className="skill-box">
                                <div className="skill-logo logo-indesign"></div>
                                <div className="skill-text">Adobe InDesign</div>
                            </div>
                            <div className="skill-box">
                                <div className="skill-logo logo-illustrator"></div>
                                <div className="skill-text">Adobe Illustrator</div>
                            </div>
                            <div className="skill-box">
                                <div className="skill-logo logo-photoshop"></div>
                                <div className="skill-text">Adobe Photoshop</div>
                            </div>
                            
                        </div>
                    </div>

                </div>
                <div className="cv-container container">
                    <div className="cv-title">Ausbildung</div>

                    <div className="cv-box">
                        <div className="cv-date">März 2018 - August 2021</div>
                        <div className="cv-description">
                            Studium an der Hochschule für Gestaltung in Schwäbisch Gmünd <br />
                            <span className="cv-extra">Bachelor of Arts im Studiengang Internet der Dinge - Gestaltung vernetzer Systeme</span>
                        </div>
                    </div>

                    <div className="cv-box">
                        <div className="cv-date">September 2010 - Juli 2017</div>
                        <div className="cv-description">
                            Gymnasium Roth <br />
                            <span className="cv-extra">Allgemeine Hochschulreife</span>
                        </div>
                    </div>

                    <div className="cv-box">
                        <div className="cv-date">September 2006 - Juli 2010</div>
                        <div className="cv-description">
                            Dr. Mehler Schule in Georgensgmünd <br />
                            <span className="cv-extra">Grundschulausbildung</span>
                        </div>
                    </div>


                    <div className="cv-title">Berufliche Erfahrung</div>
                    
                    <div className="cv-box">
                        <div className="cv-date">März 2020 - August 2020</div>
                        <div className="cv-description">
                            Praktikum bei Fraunhofer IAO in Stuttgart <br />
                            <span className="cv-extra">Team Cognitive Environments</span>
                        </div>
                    </div>

                    <div className="cv-box">
                        <div className="cv-date">Dezember 2017</div>
                        <div className="cv-description">
                            Praktikum bei promedia in Erlangen <br />
                            <span className="cv-extra">Erlernen von grafischen Grundlagen</span>
                        </div>
                    </div>

                    <div className="cv-box">
                        <div className="cv-date">Februar 2016</div>
                        <div className="cv-description">
                            Praktikum bei Och GmbH in Nürnberg <br />
                            <span className="cv-extra">Einblicke in den Vertrieb und verschiedene Bereiche der Firma </span>
                        </div>
                    </div>



                    <div className="cv-title">Ehrenamt</div>

                    <div className="cv-box">
                        <div className="cv-date">Oktober 2019</div>
                        <div className="cv-description">
                            Mentorin bei HackToTheFuture in Schwäbisch Gmünd<br />
                            <span className="cv-extra">Unterstützung von Kindern bei der Umsetzung von Projekten</span>
                        </div>
                    </div>
                    
                    <div className="cv-box">
                        <div className="cv-date">Oktober 2017 - Dezember 2017</div>
                        <div className="cv-description">
                            Freiwillige Arbeit in Valencia, Spanien <br />
                            <span className="cv-extra">Kinderbetreuung </span>
                        </div>
                    </div>

                    

                </div>
                <div className="extra-container container">
                    <div className="collage"></div>
                </div>
            </div>
        </div>
    )
}