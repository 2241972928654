import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ProjectSite from './ProjectSite';

const aquiArray = {
    title: "Aqui - Wasserroboter",
    titleImg: "p-img-aqui-1",
    description: "AQUI, der Wasserroboter sammelt Daten zur Sicherung der Wasserqualität schnell und einfach. Er bewegt sich autonom über den See und fährt die ihm vorgegeben Punkte in regelmäßigen Abständen ab. An diesen Punkten misst er die Werte in verschiedenen Wassertiefen. So können nicht nur die aktuellen Wasserwerte gemessen und die Badeseen für die Besucher freigegeben werden, sondern auch für Langzeitstudien zum ökologischen Gleichgewicht des Sees dokumentiert werden. Bisherige Wasserproben werden selten genommen und brauchen sehr lange, da sie im Labor untersucht werden. Aqui misst Echtzeitdaten, die für den Nutzer einsehbar sind.",
    team: ["Christopher Himann", "Noah Engler", "Catherine Haentges", "Isabella Wolf", "Julia Hellmann"],
    topic: ["Ardiuno", "Javascript (node.js)", "HTML", "CSS", "MySQL Datenbank"],
    context: ["Hochschulprojekt", "3.Semester", "2019"],
    content: [
        {type: "text", headline: "Technischer Aufbau", text: "Die über Ardiuno gesteuerten Sensoren sitzen in einer Kapsel, die mit einem Steppermotor nach unten gelassen wird. Die Messwerte werden in unterschiedlichen Tiefen ausgelesen und via Bluetooth an eine Datenbank weitergeleitet. Die Webseite greift auf die Werte in der Datenbank zu und macht diese im Frontend für den Nutzer mit Grafen sichtbar und verständlich. Der Roboter wird durch Solarenergie angetrieben und steht in ständiger Verbindung mit der Website, damit die Grafen stehts aktuell sind. AQUI wurde prototypisch von unserem Team, bestehend aus Produktgestaltern und Creative Technologists, umgesetzt. "},
        {type: "img", img: "p-img-aqui-2"},
        {type: "text", headline: "Grafische Oberfläche", text: "Die gesammelten Sensordaten werden auf einer Webseite angezeigt. Jeder Wert wird in Echtzeit aktualisiert und für den Nutzer durch Farbkodierungen zugänglich gemacht. Auf einer Karte werden alle Seen, von denen Daten existieren angezeigt. Pro See gibt es eine Übersichtsseite mit Informationen, was die Werte bedeuten und auch eine Detailseite pro Sensorwert, auf der weitere Informationen dargestellt werden. Das Layout wurde in Adobe XD entworfen und mit verschiedenen Frameworks von Javascript, zum Beispiel chart.js, auch realisiert."},
        {type: "img", img: "p-img-aqui-3"}

    ]
}

const designArray = {
    title: "Designing my life",
    titleImg: "p-img-design-1",
    description: "Manchmal haben wir einen Geistesblitz, eine Idee, eine kleine Notiz für uns selbst - etwas, das wir ändern, anfangen oder sogar aufhören wollen zu tun. Aber wie gehen wir weiter vor? Maßnahmen zu ergreifen scheint der schwierigste Teil zu sein. Wir bleiben in Routinen stecken, verweilen in unserer Komfortzone und vergessen unsere großen Pläne. Was könnte uns helfen, vom Denken in die Umsetzung zu kommen? Wie können wir uns mobilisieren? Das Leben als Design-Projekt zu betrachten, könnte nützen! Wir hatten die Idee, ein Framework zu entwickeln, das nicht nur Designern, sondern jedem von uns hilft, ein Design-Thinking-Mindset zu adaptieren, um alle Probleme, die im Leben auftreten, so anzugehen, dass sie mit Kreativität und Freude bewältigt werden können. Warum sollten wir nicht einen Design Sprint für unsere eigenen Bedürfnisse nutzen, wenn wir uns festgefahren fühlen?",
    team: ["Viola Keser", "Julia Hellmann"],
    topic: ["Design Thinking", "UX / UI Design", "Figma"],
    context: ["Hochschulprojekt", "6. Semester", "2021"],
    content: [
        {type: "text", headline: "Problemstellung", text: "Grundlegend sind die Hindernisse, die auftreten, dass man im Planen feststeckt, die Orientierung im Alltag fehlt. Außerdem machen Ziele Druck, da man sie mit Verzicht und Anstrengung in Verbindung setzt. Deshalb haben wir uns gefragt, wie wir es Menschen, die eine Veränderung anstreben, erleichtern können vom Thinking ins langfristige Doing zu kommen?"},
        {type: "img", img: "p-img-design-2"},
        {type: "text", headline: "Konzept", text: "Wir wollen den mobilisierenden Charakter des Design Sprints nutzen, um den Nutzer mit der Design-Thinking Arbeitsweise vertraut zu machen. Er beginnt mit einem begleiteten Sprint, wobei er eine Einführung in die Phasen erhält. Pro Phase werden verschiedene Methoden vorgestellt, darunter einsteigerfreundliche Optionen gekennzeichnet. Der Nutzer wird im ersten Sprint an die Hand genommen und während der Anwendung unterstützt. Er beginnt sich selbst besser zu verstehen, analysiert Probleme, die ihn an der Umsetzung hindern und versucht diese zu lösen und die entstandenen Lösungsansätze zu testen. Durch Prozessorientierung bleibt die App anpassungsfähig und auch relevant, wenn sich die persönlichen Anliegen ändern."},
        {type: "text", headline: "Ablauf", text: "Auf dem Homescreen kann der Nutzer seinen bisherigen Prozess einsehen und mit der aktuellen Phase beginnen. Beim Start erhält er ein kurzes Intro und Tipps. Im geführten Sprint werden ihm drei Methoden dieser Phase vorgeschlagen. Zu jeder dieser Methoden gibt es Erklärungen zur Funktion, Umsetzung und deren Zweck. Wählt der Nutzer eine Methode aus, erhält er eine Schritt-für-Schritt Anleitung, wie er vorgehen muss. Nach Abschluss der Methode fasst der Nutzer seine Erkenntnisse zusammen, um diese zu dokumentieren. Sein Prozess bleibt in jeder Phase zugänglich und hilft beim Treffen von Entscheidungen. Ist eine Phase abgeschlossen, erhält der Nutzer einen Teaser für den folgenden Tag. Nachdem der Sprint einmal durchlaufen wurde, hat der Nutzer die Auswahl, mit welcher Phase er weitermachen will. Hier kann der Nutzer freier werden und seinen Prozess individuell gestalten. Der Sprint wandelt sich zu einem Design Thinking Prozess, in dem der Nutzer Unterstützung durch Methoden erhält, aber die Phasen frei wählen kann."},
        {type: "img", img: "p-img-design-3"},
        {type: "img", img: "p-img-design-4"},
    ]
}

// const verdeckungArray = {
//     title: "Verdeckung",
//     titleImg: "p-img-verdeckung-1",
//     description: "Verdeckung beschreibt einen Effekt, bei dem es dem menschlichen Gehör nicht mehr möglich ist bestimmte Freuquenzen wahrzunehmen. Durch eine interaktive Ausstellungssituation wollen wir dieses akustische Phänomen erklären. Hierbei werden einzelne Instrumente durch Farben repräsentiert. Die Helligkeit der LEDs und die Visualisierung der Frequenzen können so einem Instrument zugeordnet werden. Durch Verstellen dieser anhand von Drehreglern kann das Phänomen in Echtzeit erlebt werden.",
//     team: ["Celiné Offray", "Johanna Luz", "Julia Hellmann"],
//     topic: ["Ausstellung", "Wissensvermittlung", "Arduino", "Javascript", "UI / UX Design"],
//     context: ["Hochschulprojekt", "4. Semester", "2020"],
//     content: [
//         {type: "text", headline: "Was ist Verdeckung?", text: "Das akustische Phänomen Verdeckung, oder auch Maskierungseffekt genannt, beschreibt den Effekt, dass das menschliche Gehör bestimmte Frequenzteile gar nicht oder nur teilweise wahrnehmen kann. So können laute Bässe leise mittlere Frequenzen verdecken. Dies ist dem Aufbau des Innenohrs geschuldet. Die Membran gerät ins Schwingen und dämpft nachfolgende Freuquenzen ab, da die Empfindlichkeit sinkt."},
//         {type: "text", headline: "Ausstellungssituation", text: "Das Projekt entstand in einer Kooperation mit der experimenta in Heilbronn. Dort werden die verschiedensten Themen über Biologie bis hin zu Physik Kindern näher gebracht durch aktives Ausprobieren an Kreativstationen. Unser Konzpt sollte deshalb interaktiv sein und wissenschaftliche Themen spaßig und einfach vermitteln. Ausgestellt wurde das Projekt an der halbjählichen Ausstellung der Hochschule für Gestlatung in Schwäbisch Gmünd."},
//         {type: "text", headline: "Konzept", text: "Unser Konzept geht auf die Frequenzen ein, die sich gegenseitig verdecken. Hierfür wurden vier Instrumente ausgewählt, die ein Lied spielen. Durch Drehregler können die Lautstärken beeinflusst werden, sodass Töne nicht mehr gehört werden können, obwohl sie noch abgespielt werden. Die Lautstärken werden durch LED-Lichter angezeigt. Die angestrahlten Gelasplatten zeigen eine Momentaufnahme, während im Hintergrund eine Echtzeitvisualisierung läuft. Durch das interaktive Pult kann auch auf ein einfaches Beispiel umgeschalten werden. Dies ist ein Ticken, das durch einen Alarmton verdeckt wird. Neben der Ausstellungssituation hängt außerdem ein Plakat, welches das Phänomen im Detail erklärt. "},
//         {type: "img", img: "p-img-verdeckung-2"},
//         {type: "img", img: "p-img-verdeckung-3"},
//         {type: "text", headline: "Technische Umsetzung", text: "Zur Steuerung der einzelnen Instrumente und zum Umschalten auf eine andere Situation wurden Potentiometer und Taster an einen Arduino angeschlossen. Von dort werden die Werte genutzt, um die Helligkeit der LEDs anzupassen und gleichzeitig an einen node server geschickt, der die Lautstärke regelt und die Live-Visualisierung ausgibt. Die Visualisierung wurde durch p5.js realisiert."},
        
//     ]
// }

// const shoppingArray = {
//     title: "Einkaufsliste",
//     titleImg: "p-img-design-1",
//     description: "",
//     team: ["Julia Hellmann"],
//     topic: ["Javascript", "HTML", "CSS", "php"],
//     context: ["privates Projekt", "2020"],
//     content: [
//         {type: "text", headline: "", text: ""},
//         {type: "img", img: "p-img-aqui-2"}
//     ]
// }

const ortheseArray = {
    title: "exoJack - smarte Orthese",
    titleImg: "p-img-orthese-1",
    description: "exoJack ist eine smarte Orthese für Sprunggelenksbrüche, mit der wir die aktuelle konservative Behandlung mit Gipsverbänden ablösen wollen. Die individuell anpassbare Orthese besteht aus einem stabilen Cast zur Fixierung und einem atmungsaktiven Sleeve mit eingenähten Luftkammern zur Stabilität und Sensoren zur Überwachung des Heilungsprozesses. Die Werte werden in sinnvollen Messintervallen an eine verknüpfte App geschickt, die außerdem weitere Informationen, wie zum Beispiel Übungen als Ergänzung zur Physiotherapie und Belastungstests bietet. Durch das optimale Zusammenspiel aus Ergonomie und Technik ermöglicht unser Produkt einen besseren Einblick in den Heilungsprozess und bietet dem Patienten Sicherheit durch eine individuelle Anpassung und Unterstützung im Alltag.",
    team: ["Guillaume Köster", "Nils Travnicek", "Christopher Himann", "Julia Hellmann"],
    topic: ["UI / UX Design", "Sensorik", "Arduino", "Figma"],
    context: ["Hochschulprojekt", "6. Semester", "2021"],
    content: [
        {type: "text", headline: "Problemstellung", text: "Sprunggelenksbrüche gehören zu den häufigsten Verletzungen der unteren Extremitäten. Bei der aktuellen konservativen Behandlung mit Gipsverbänden sind die Durchlüftung und eingeschränkte Bewegungsfreiheit Probleme für den  Patienten. Außerdem ist er schwer, man hat keinen Einblick in den Heilungsprozess, die Muskeln werden abgebaut und der Verband wird nach einmaliger Benutzung weggeworfen."},
        {type: "text", headline: "Konzept", text: "Wegen dieser Probleme wollen wir Patienten mit Sprunggelenksbrüchen eine Alternative bieten. Unsere smarte Orthese exoJack ist individuell anpassbar an jeden Fuß und jede Phase im Heilungsprozess, bietet ausreichend Durchlüftung, überwacht den Heilungsfortschritt durch Sensorik, besteht aus leichten Materialien und kann wiederverwertet werden im Gegensatz zu Gipsverbänden. "},
        {type: "text", headline: "Aufbau", text: "Unsere Orthese besteht aus zwei Teilen. Zum einen gitbt es den stabilen Cast, der zum Anlegen geöffnet werden kann, und zur Fixierung am Knöchel dient. Die Freiräume ermöglichen eine Luftzirkulation und ein Ansteckbares Modul enthält einen Akku und eine Steuereinheit für die Sensoren im Sleeve. Dieser liegt an der Haut an, besteht aus einem atmungsaktiven Gewebe und hat Belastungssensoren und einen Pulsoximeter, um den Blutdruck, Sauerstoffgehalt und die Belastung zu überwachen. Im Stoff eingenähte Luftkammern stabilisieren den Bruch."},
        {type: "img", img: "p-img-orthese-2"},
        {type: "text", headline: "App", text: "Um den Patienten einen besseren Einblick in die unterstützenden Vorgänge der Orthese zu bieten, gibt es eine App, die die Daten in einen für den Nutzer verständlichen Kontext setzt. Die App bietet gibt einen Überblick über den Heilungsfortschritt und die Vitalwerte, gibt Informationen zu Brüchen und Tipps zur schnellen Heilung und enthält Kontaktdaten zu Ärzten. Außerdem können Belastungs- Fixierungs- und Bewegungstests durchgeführt werden und es gibt Übungen als Ergänzung zur Physiotherapie, die je nach Heilungsfortschritt freigeschalten werden."},
        {type: "img", img: "p-img-orthese-3"}
    ]
}

// const essenArray = {
//     title: "Essensplanungs-App",
//     titleImg: "p-img-essen-1",
//     description: "Essen ist ein großes Thema in der heutigen Zeit. Groß diskutiert wird auch, wie man der Essensverschwendung entgegenwirken kann. Das Konzept hinter der App ist es durch bewusstes Planen und Einkaufen darauf zu achten, dass keine Zutaten im Kühlschrank vergessen werden und eingeplante Zutaten werden nicht vergessen, wenn ein spontes Gelüst nach Tiefkühlpizza überwiegt. Gleichzeitig kann man seine Lieblings- rezepte an einem Ort speichern, da diese sonst überall verteilt sind. Online Links und handgeschriebene Rezepte mit Notizen müssen endlich nicht mehr lange gesucht werden.",
//     team: ["Julia Hellmann"],
//     topic: ["Javascript", "HTML", "CSS", "php", "Figma"],
//     context: ["privates Projekt",  "2021"],
//     content: [
//         {type: "text", headline: "Ablauf", text: "Die App, die durch die mobile Plattform schnell, ein- fach und unterwegs genutzt werden kann, ist in drei Teile gegliedert, die verschiedene Funktionen haben, aber ineinander greifen und einheitliche Farben, Icons und Sprache verwenden."},
//         {type: "text", headline: "Die Einkaufsliste", text: "In der Einkaufsliste werden die benötigten Zutaten in Kategorien eingeteilt. Neben der Zutat wird auch die zu kaufende Menge angeben. Die Zutat kann nach dem Kauf abgehakt werden und auch komplett gelöscht werden."},
//         {type: "text", headline: "Der Essensplan", text: "Im Essensplan können Gerichte pro Tag aufgeschrieben werden. Dabei sieht man, ob das Gericht vegetarisch, vegan oder mit Fleisch oder Fisch ist, um eine ausgewogene Ernährung zu gewährleisten. Die Gerichte sind mit Drag and Drop verschiebbar und können einfach gelöscht werden. "},
//         {type: "img", img: "p-img-essen-2"},
//         {type: "text", headline: "Die Rezepte", text: "Rezepte können hier an einem Ort gesammelt werden. Online Links können mit Notizen individuell angepasst werden und immer wieder aufgerufen werden. Auch handgeschriebene Rezepte können hier eingepflegt werden."},
//         {type: "img", img: "p-img-essen-3"},
//         {type: "text", headline: "Technische Umsetzung", text: "Teile der App sind technisch mit Javascript zur Funktionalität, HTML und CSS zum Aufbau und zur Gestaltung und mit php und einer MySQL-Datenbank zur Datenspeicherung umgesetzt. Die Einkaufsliste und eine Auflistung von geplanten Gerichten sind vorhanden. Für die Umsetzung wurde ein Prototyp in Figma als Grundlage verwendet."},
//         {type: "img", img: "p-img-essen-4"}
//     ]
// }


const profectumArray = {
    title: "profectum - Modulares System zur Unterstützung der Medikamenteneinnahme",
    titleImg: "p-img-profectum-1",
    description: "profectum ist ein modulares System zur Unterstützung der Medikamenteneinnahme und zur Vermeidung von Wechselwirkungen. Es ist schnell zugänglich und individuell anpassbar durch ein Nutzerkonto. Jedes Modul von profectum geht auf verschiedene Probleme bei der Medikamenteneinnahme ein. Der Beipackzettel listet allgemeine Informationen übersichtlich auf, der Wechselwirkungscheck ermöglicht eine schnelle Aufklärung, ob die gesuchten Medikamente miteinander eingenommen werden können. Der Medikationsplan gibt Auskunft über die einzunehmenden Arzneimittel und das Profil speichert persönliche Daten, um individuelle Warnungen und Hinweise auszugeben, die bei der Vermeidung von Wechselwirkungen helfen. Zusätzlich können Kontaktdaten von Ärzten, Termine, Dokumente und Diagnosen in den Modulen Verlauf und Ärzte gespeichert werden. Zur weiteren Aufklärung des Nutzers zum Thema Gesundheit sind Artikel im Modul Wissenswertes zu finden.",
    team: ["Antje Schmid", "Fiona Philipp", "Julia Hellmann"],
    topic: ["UX / UI Design", "Medizin", "Figma", "", " "],
    context: ["Hochschulprojekt", "7. Semester", "Bachelorprojekt", "2021"],
    content: [
        {type: "text", headline: "", text: "profectum ist ein modulares System, das Nutzern bei der Einnahme von Medikamenten unterstützt und auf Wechselwirkungen aufmerksam macht"},
        {type: "text", headline: "Problemstellung", text: "35.000 Menschen sterben jährlich an den Folgen einer falschen Medikation allein in Deutschland. Gründe dafür sind unter Anderem Unsicherheiten in der Einnahme, das Vergessen wichtiger Medikation und eine zunehmende Selbstmedikation, bei der Patienten Medikamente in eigenem Ermessen absetzen oder durch ihren Lebensstil die Wirksamkeit verändern. Auch eine schlechte Kommunikation zwischen Ärzten untereinander, welche zu doppelten oder nicht zueinanderpassenden Verschreibungen führen, können eine entscheidende Rolle spielen. Durch Medikationspläne und Beipackzettel wird versucht der Problematik entgegenzuwirken. Diese sind jedoch hauptsächlich in Papierform erhältlich und dadurch nicht sonderlich flexibel. Der Beipackzettel ist schwer lesbar, schnell veraltet und Informationen werden überlesen oder gänzlich ignoriert. Beim Medikationsplan ist die fehlende Kommunikation zwischen verschiedenen Fachärzten und der fehlende Einbezug von persönlichen Medikamenteneinnahmen und Lebensmitteln ein Problem."},
        {type: "text", headline: "", text: "Wie können wir Patienten, die viele Medikamente gleichzeitig einnehmen, beim frühzeitigen Erkennen von Wechselwirkungen unterstützen?"},
        {type: "text", headline: "Konzept", text: "profectum ist unser modulares System zur Unterstützung bei der Medikamenteneinnahme. Durch die verschiedenen Module kann auf die unterschiedlichen Probleme bei der Medikation individuell eingegangen werden. Zur besseren Zugänglichkeit wird für die Module Beipackzettel, Wechselwirkungscheck und Wissenswertes keine Registrierung oder Anmeldung benötigt. Es wurde außerdem darauf geachtet, dass profectum sowohl von älteren als auch jüngeren Generationen genutzt werde kann, da ältere Generationen häufig viele Medikamente gleichzeitig einnehmen müssen und jüngere Generationen so früh wie möglich aufgeklärt werden sollten, um Unwissenheitsfehler möglichst zu vermeiden."},
        {type: "img", img: "p-img-profectum-2"},
        {type: "text", headline: "Module", text: "Der Beipackzettel bietet allgemeine Informationen zu einem gesuchten Medikament in einem übersichtlichen und einfach verständlichen Format. Bei einem eingeloggten Nutzer werden zusätzlich persönliche Warnungen ausgegeben."},
        {type: "img", img: "p-img-profectum-3"},
        {type: "text", headline: "", text: "Der Wechselwirkungscheck dient zur schnellen Überprüfung, ob Medikamente miteinander eingenommen werden können. Durch Farbcodierungen wird der Schweregrad der Wechselwirkung angegeben."},
        {type: "img", img: "p-img-profectum-4"},
        {type: "text", headline: "", text: "Im Modul Wissenswertes kann der Nutzer sich interessante Artikel zum Thema Gesundheit, Krankheiten und Medikamente durchlesen und sich weiterbilden."},
        {type: "img", img: "p-img-profectum-5"},
        {type: "text", headline: "", text: "Der Medikationsplan listet die einzunehmenden Medikamente auf und zeigt auch, welche Medikamente als Nächstes eingenommen werden sollen. "},
        {type: "img", img: "p-img-profectum-6"},
        {type: "text", headline: "", text: "Beim Modul Mein Verlauf werden Termine, Diagnosen, Dokumente und Medikamenteneinnahmen aufgelistet. Dies dient als aktuelle persönliche Gesundheitshistorie und als Speicherort für Zugangsdaten zu Online-Befunden."},
        {type: "img", img: "p-img-profectum-7"},
        {type: "text", headline: "", text: "Das Modul Ärzte speichert Kontaktdaten von Ärzten und enthält einen Terminkalender mit allen zukünftigen Arztterminen."},
        {type: "img", img: "p-img-profectum-8"},
        {type: "text", headline: "", text: "Das Profil bietet eine Übersicht für die Medkamenteneinnahme und anstehende Termine. Zusätzlich werden hier persönliche Daten zu Vorerkrankungen, Allergien, Intoleranzen und Konsum gespeichtert."},
        {type: "img", img: "p-img-profectum-9"},

        {type: "text", headline: "Mehrwert", text: "profectum vereint eine Vielzahl von bereits existierenden Werkzeugen, entwickelt sie nutzerfreundlich weiter und stimmt sie aufeinander ab. Alle Module sind miteinander verknüpft und profitieren von den in anderen Modulen hinterlegten Daten, so können beispielsweise im 'Beipackzettel', 'Wechselwirkungscheck' und 'Medikationsplan' Warnungen ausgegeben werden, wenn Medikamente nicht für den Nutzer geeignet sind. Der digitale Beipackzettel beinhaltet alle Informationen eines analogen Beipackzettels, ist im Gegensatz dazu aber stetig und schnell aktualisierbar, so können auch spezifische Warnungen, wie Rückrufaktionen, unabhängig angezeigt werden. Ob ein Medikament eingenommen werden darf ist übersichtlich und auf einen Blick erkennbar. Fachbegriffe sind hervorgehoben und bieten weitere Erklärungen zu ihrem Inhalt. Er greift außerdem auf Datenbanken zu, um Medikamente mit ähnlicher Wirkung anzuzeigen. Der 'Wechselwirkungscheck' ist durch eine Gliederung übersichtlicher und leichter zu bedienen als viele seiner bereits existieren Konkurrenten und besitzt in der Suche die Möglichkeit vorherige Suchen wieder aufzunehmen. Im 'Verlauf' werden Dokumente, Termine und Zugänge zu Online-Befunden an einem Ort gespeichert und zeitlich geordnet und Medikamenteneinnahmen sind nicht nur auf rezeptpflichtige Medikamente beschränkt. Außerdem werden sie nicht erst zu Quartalsenden angezeigt. Einen weiteren Vorteil bietet profectum, indem nicht alle Module ausschließlich eingeloggt verfügbar sind. 'Beipackzettel', 'Wechselwirkungscheck' und 'Wissenswertes' sind auch für nicht angemeldete Nutzer verfügbar, natürlich in diesem Fall ohne die Daten der anderen Module. Des Weiteren ist profectum auf verschiedenen Plattformen verfügbar."},
        {type: "img", img: "p-img-profectum-10"},
        {type: "text", headline: "Ausblick", text: "Mit profectum im jetzigen Stand kann der Patient Wechselwirkungen verhindern, muss sich danach jedoch mit dem behandelnden Arzt absprechen und ein neues Rezept muss ggf. verschrieben werden. Da Ärzte allerdings verschiedene Software benutzen ist es nicht einfach eine einheitliche Lösung zu finden, um bereits hier anzusetzen. Zukünftig könnte beispielsweise ein profectum-Plugin entwickelt werden, welches mit allen gängigen Softwares der Ärzte kompatibel ist und die Wechselwirkungen unter Zustimmung des Patienten bereits erkennt bevor ein Rezept überhaupt ausgestellt wurde. Zukünftig können wir uns auch eine Einbindung von der digitalen Patientenakte und dem e-Rezept - die sich zurzeit in der Testphase befinden - vorstellen. Auch eine Funktion, die mehrere Profile von Mitgliedern eines Haushalts mit deren persönlichen Daten umfasst, empfinden wir als sinnvoll."},
        
        {type: "text", headline: "", text: ""}
    ]
}

// const emptyArray = {
//     title: "",
//     titleImg: "p-img-design-1",
//     description: "",
//     team: ["", "", "", "", ""],
//     topic: ["", "", "", "", " "],
//     context: ["", "3.", ""],
//     content: [
//         {type: "text", headline: "", text: ""},
//         {type: "img", img: "p-img-aqui-2"}
//     ]
// }

export default function ProjectList({text}) {
    
    const { path } = useRouteMatch();    

    return (
        <>

            <Switch>
                <Route path={`${path}/aqui`}>
                    <ProjectSite array={aquiArray} />
                </Route>
                <Route path={`${path}/design`}>
                    <ProjectSite array={designArray} />
                </Route>
                <Route path={`${path}/profectum`}>
                    <ProjectSite array={profectumArray} />
                </Route>
                <Route path={`${path}/exojack`}>
                    <ProjectSite array={ortheseArray} />
                </Route>
                
            </Switch>
            
        </>
    );
   
      
}