import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import ProjectList from './components/ProjectList';

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path='/' exact component={Home}/>
          <Route path='/about' component={About}/>
          <Route path="/projects" component={ProjectList} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
