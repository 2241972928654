import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css'

export default function Button ({text, home}) {
    if(home) {
        return (
            <>
                <Link to='#' className="btn"
                    onClick={(e) => {
                        window.location = "mailto:julia.hellmann@hfg.design";
                        e.preventDefault();
                    }}
                > 
                {text} 
                </Link>
                <div className="mail"> <i className="mail-icon far fa-envelope"></i> julia.hellmann@hfg.design</div>
            </>
        )
    }
    else {
        return <div className="btn-project">{text}</div>
    }
}
