import React, { useEffect } from 'react';
import uuid from 'react-uuid';
import { Link } from 'react-router-dom'

export default function ProjectSite({ array }) {

    useEffect(() => {
        window.scroll(0, 0)
    }, []);

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <div className="p">
            
            <div className="scroll-btn" onClick={scrollToTop}><i className="fas fa-chevron-up"></i></div>

            <Link className="p-back" to="/">zurück</Link>
            <div className="p-head-wrapper">
                <div className={"p-image " + array.titleImg}></div>
                <div className="p-intro">
                    <div className="p-title">{array.title}</div>
                    <div className="p-description">
                        {array.description}
                    </div>
                </div>
            </div>

            <div className="p-box">
                <div className="p-boxes">
                    <div className="p-boxtitle">Team</div>
                    {array.team.map((team, index) => { 
                        return <div key={uuid()}>{team}</div>
                    })}
                    
                    
                </div>
                <div className="p-boxes">
                    <div className="p-boxtitle">Themen</div>
                    {array.topic.map((topic, index) => { 
                        return <div key={uuid()}>{topic}</div>
                    })}
                </div>
                <div className="p-boxes">
                    <div className="p-boxtitle">Kontext</div>
                    {array.context.map((context, index) => { 
                        return <div key={uuid()}>{context}</div>
                    })}
                </div>
            </div>


            {array.content.map((content) => {
                if(content.type === "img") {
                    return (
                    <div className="p-row" key={uuid()}>
                        <div className={"p-img " + content.img}></div>
                    </div>
                    )
                }
                else if(content.type === "text") {
                    return (
                    <div className="p-row" key={uuid()}>
                        <div className="p-subtitle">{content.headline}</div>
                        <div className="p-col">{content.text}</div>
                    </div>
                    )
                }
                else {
                    return <div></div>
                }
                
            })}


        </div>  
    );
}
