import React from 'react';
import { Link } from 'react-router-dom'
import Button from './Button';

export default function Sidebar({about}) {
    
    return(
        <div className={about ? "sidebar-about" : "sidebar"}>
            <Link className={about ? 'sidebar-back' : 'sidebar-invisible'} to="/">zurück</Link>
            <div className="sidebar-title">
                Designer. <br />
                Creative Coder. <br />
                Developer.
            </div>
            <div className="sidebar-text">
                Ich heiße Julia Hellmann und bin Studentin an der Hochschule für Gestaltung in Schwäbisch Gmünd.
                Dort studiere ich "Internet der Dinge - Gestaltung vernetzter Systeme". 
                Meine Leidenschaft ist das Programmieren und Gestalten von Applikationen.
                <br />
                <span> <Link to="/about" className={about ? "invisible" : "more"}>Mehr erfahren ...</Link></span>
                <span className={about ? "" : "invisible"}>
                    Durch Design Thinking Methoden lerne ich die Bedürfnisse von Nutzern kennen 
                    und gestalte dementsprechend. 
                    Ich bin offen für Neues und versuche meine Kenntnisse immer zu erweitern.
                </span>
            </div>

            <div className="social-wrapper">
                <Link to={{ pathname: "https://www.linkedin.com/in/julia-hellmann-bb4464195/" }} target="_blank">
                    <i className="social-icon fab fa-linkedin"></i>
                </Link>
                <Link to={{ pathname: "https://dribbble.com/lia284" }} target="_blank">
                    <i className="social-icon fab fa-dribbble"></i>
                </Link>
                {/* <Link to={{ pathname: "https://example.com/1234" }} target="_blank">
                    <i className="social-icon fab fa-github"></i>
                </Link> */}
                
            </div>

            <Button home={true} text="Schreib mich an!" />
        </div>
    )
}